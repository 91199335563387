import { Modal, Typography, Box, TextField, Button, IconButton, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { useReputation } from "../useReputation";
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { PrivateReview_Reputation as PrivateReview } from "@safelease/service-utilities";
import { GoogleReview_Reputation as GoogleReview } from "@safelease/service-utilities";
import { mixpanelEventHandler } from "../../utilities/reactMixpanelHandler";

interface GeneratedResponseModalProps {
  reviewId: string;
  open?: boolean;
  onClose?: () => void;
  reviewType: "google" | "private";
  review?: PrivateReview | GoogleReview;
}

export function GeneratedResponseModal({ reviewId, open, onClose, reviewType, review }: GeneratedResponseModalProps) {
  const [editedResponse, setEditedResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { SafeLeaseAPI } = useReputation();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingMessage, setLoadingMessage] = useState("Reading review...");


  useEffect(() => {
    if (open) {
      handleGenerate();
    } else {
      setEditedResponse("");
      setIsLoading(false);
    }
  }, [open]);

  useEffect(() => {
    if (isLoading) {
      setLoadingMessage("Reading review...");

      const processingTimeout = setTimeout(() => {
        setLoadingMessage("Processing review...");
      }, 2000);

      const generatingTimeout = setTimeout(() => {
        setLoadingMessage("Generating response...");
      }, 4000);

      return () => {
        clearTimeout(processingTimeout);
        clearTimeout(generatingTimeout);
      };
    }
  }, [isLoading]);

  console.log("review:", (review as PrivateReview)?.feedback);

  const handleGenerate = async () => {
    if ((reviewType === "private" && !(review as PrivateReview)?.feedback) ||
        (reviewType === "google" && !(review as GoogleReview)?.reviewSnippet)) {
      setEditedResponse(review.rating <= 3 ? "Thank you for providing your feedback. We would love to discuss how we can improve our services. Please reach out to our facility manager to discuss your experience." : `Thank you for leaving a ${review.rating} star review! We appreciate your feedback and will continue to work hard to provide the best experience for you!`);
      mixpanelEventHandler('No Review Text Provided', {
        reviewId,
        reviewType,
        DefaultResponse: editedResponse
      });
      setIsLoading(false);

      return;
    }

    try {
      setIsLoading(true);
      const response = await (reviewType === "private"
        ? SafeLeaseAPI.reputation.getGeneratedResponsesForPrivateReviews({reviewId})
        : SafeLeaseAPI.reputation.getGeneratedResponseForGoogleReviews({reviewId})
      );
      mixpanelEventHandler("Response Generated", {
        reviewId,
        reviewType,
        responseLength: response.data.responseText.length,
      });
      setEditedResponse(response.data.responseText);
    } catch (error) {
      enqueueSnackbar('Failed to generate response', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(editedResponse);
      onClose();
      enqueueSnackbar('Response copied to clipboard', { variant: 'success' });
    } catch (error) {
      console.error('Failed to copy text:', error);
      enqueueSnackbar('Failed to copy response', { variant: 'error' });
    }
  };

  const handleNewResponse = () => {
    setEditedResponse("");
    handleGenerate();
  };

  const handleSendEmail = (review?: PrivateReview | GoogleReview) => {
    const emailSubject = "Thank you for your review!";
    const emailBody = encodeURIComponent(editedResponse);
    const to = review?.contact?.email;
    window.location.href = `mailto:${to}?subject=${emailSubject}&body=${emailBody}`;
  };


  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 1,
          outline: 'none',
          maxWidth: 500,
          width: '90%',
          position: 'relative',
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2">
          {!isLoading ? "We've generated a response for you" : " "}
        </Typography>

        <Typography variant="subtitle2" sx={{ mb: 2, color: "gray" }}>
          {isLoading ? " " : "You can edit the response and copy it to the clipboard."}
        </Typography>

        {isLoading ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 4 }}>
            <CircularProgress sx={{ mb: 2 }} />
            <Typography>{loadingMessage}</Typography>
          </Box>
        ) : (
          <>
            <TextField
              multiline
              rows={4}
              fullWidth
              placeholder="Generated response will appear here..."
              value={editedResponse}
              onChange={(e) => setEditedResponse(e.target.value)}
              sx={{ mb: 3 }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button variant="outlined" onClick={handleNewResponse}>
                New Response
              </Button>
              {review && (
                <Button variant="outlined" onClick={() => handleSendEmail(review)}>
                  Send Email
                </Button>
              )}
              <Button variant="contained" onClick={handleCopy}>
                Copy Response
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
}
